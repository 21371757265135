import React from "react";

import * as S from "./styled";
import CatBorderedTitle from "../CatBorderedTitle";
import { useStaticQuery, graphql } from "gatsby";

const CatSolution = () => {
  const { solution } = useStaticQuery(
    graphql`
      query {
        solution: file(relativePath: { eq: "CatSolution/solution.svg" }) { publicURL }
      }
    `
  );
  return (
    <S.CatSolution>
      <S.PurpleColumn />      
      <S.TitleWrapper>
        <CatBorderedTitle
          align="center"            
          title={"Atuando no seu negócio"}
        />
        <S.Subtitle>
          O objetivo da <strong>CAT</strong> é transformar o <strong>Big Data</strong> e a <strong>Ciência de Dados</strong> em algo fácil<br />
          de entender e útil para o seu negócio, contribuindo para que seus principais<br />
          times estratégicos tomem decisões <strong>melhores</strong> e possam empenhar mais<br />
          tempo sendo <strong>criativos e inovadores.</strong>
        </S.Subtitle>
      </S.TitleWrapper>
      <img src={ solution.publicURL } className="solution-image" alt="Soluções da CAT" />
    </S.CatSolution>
  );
};

export default CatSolution;
