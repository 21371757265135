import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import * as S from "./styled";

const CatAbout = () => {
  const { rectangle, logo, bigData } = useStaticQuery(
    graphql`
      query {
        rectangle: file(relativePath: { eq: "CatCommon/rectangle.svg" }) { publicURL }
        logo: file(relativePath: { eq: "CatBigData/logo.svg" }) { publicURL }
        bigData: file(relativePath: { eq: "CatBigData/bigData.png" }) { publicURL }
      }
    `
  );

  return (
      <S.CatAbout>
        <S.Wrapper>
          <img src={ rectangle.publicURL } className="title-icon" alt="Retângulo Roxo" />
          <S.WrapperText>
            <S.Title>
              Evolua para a<br />
              Inteligência<br />
              Omnichannel<br />
            </S.Title>
            <S.Spacer />
            <S.Subtitle>
              A <strong>CAT</strong> "Customer Analytics Tool" é uma<br />
              ferramenta que fornece <strong>insights</strong> para<br />
              empresas tomarem decisões<br />
              inteligentes, ajudando-as a enxergarem<br />
              o <strong>Big Data</strong> de forma clara e simplificada<br />
              nas interações que ocorrem da sua<br />
              <strong>marca</strong> com o <strong>mercado</strong> e <strong>seus cliente</strong>.
            </S.Subtitle>            
          </S.WrapperText>          
        </S.Wrapper>
        <S.Spacer />                
      </S.CatAbout>      
  );
};

export default CatAbout;