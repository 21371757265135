import styled from "styled-components";
import media from "styled-media-query";

export const CatSolution = styled.div`
  background-color: #2A2944;
  padding: 3rem var(--default-padding-desktop);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size:30px;
  ${media.lessThan("medium")`
    padding: var(--default-padding-mobile);
  `}

  .solution-image {
    width: 80%;
    margin-top: 25px;
  }
`;

export const PurpleColumn = styled.div`
  width: 10px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #8630F2;
  
`;

export const TitleWrapper = styled.div``;
export const Subtitle = styled.label`
  font-family: Exo2;
  color: #FFFFFF;
  
  strong {
    color: #8630F2;
  }
`;