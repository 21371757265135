import styled from "styled-components";
import media from "styled-media-query";

export const CatBigData = styled.div`
  background-color: #E0E0E0;    
  padding: 3rem var(--default-padding-desktop);
  padding-left: 14.5%;
  padding-bottom: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  // align-items: center;
  align-items: baseline;
 
  ${media.lessThan("medium")`
    padding: var(--default-padding-mobile);
  `}

  .bigData-image {    
    width: auto;
    height: 100%;
    margin-right: 0%;
    position: absolute;
    right: 0;
    bottom: 0;   
  }
`;

export const Wrapper = styled.div`
  // margin-left: -55%;  
  align-items: baseline;

  .title-icon {
    width: 15px;
    height: 15px;
    margin-top: 17px;
    float: left;
  }
`;

export const WrapperText = styled.div`
  padding-left: 30px;
`;

export const Title = styled.h1`  
  font-family: Exo2;
  font-weight: bold;
  color: #2A2944;
  font-size: var(--medium-title-size);
`;

export const Subtitle = styled.label`
  display: block;
  font-family: Exo2;
  font-weight: 500;
  color: #271C3E;
`;

export const Spacer = styled.div`
  height: 28px;
`;

export const Footer = styled.div`
  width: 100%;
  padding-left: 25px;
  align-items: center;
  
  .logo {
    margin-top: 20px;
    margin-left: -50px;
    width: 100px;
    height: 100px;    
    background-color: transparent;   
    float: left;
  }
`;

export const FootNote = styled.div`
  padding: 30px 0;
  padding-left: 70px;
  background-color: #8630F2;
`;

export const FootNoteText = styled.span`
  font-family: Exo2;
  font-weight: bold;
  font-size: 15pt;
  color: #ffffff;
`;