import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import * as S from "./styled";

const CatBigData = () => {
  const { rectangle, logo, bigData } = useStaticQuery(
    graphql`
      query {
        rectangle: file(relativePath: { eq: "CatCommon/rectangle.svg" }) { publicURL }
        logo: file(relativePath: { eq: "CatBigData/logo.svg" }) { publicURL }
        bigData: file(relativePath: { eq: "CatBigData/bigData1.png" }) { publicURL }
      }
    `
  );

  return (
      <S.CatBigData>
        <S.Wrapper>
          <img src={ rectangle.publicURL } className="title-icon" alt="Retângulo Roxo" />
          <S.WrapperText>
            <S.Title>
              A CAT solucionou a<br />
              complexidade do Big Data
            </S.Title>
            <S.Spacer />
            <S.Subtitle>
              Dados são cada vez mais importantes no mercado<br />
              atual e se tornaram peça-chave para orientar a<br />
              tomada de decisão e o planejamento estratégico de<br />
              empresas de todos os segmentos.
            </S.Subtitle>
            <S.Spacer />
            <S.Subtitle>
              No entanto, para transformar essas informações em<br />
              conhecimento e vantagens competitivas de verdade, é<br />
              preciso que as organizações saibam, primeiro, como<br />
              extrair, organizar e analisar todos esses dados.
            </S.Subtitle>
          </S.WrapperText>          
        </S.Wrapper>
        <S.Spacer />
        <img src={ bigData.publicURL } className="bigData-image" alt="Imagem Big Data" />
        <S.Footer>
          <img src={ logo.publicURL } className="logo" alt="Logo da CAT" />
          <S.FootNote>
            <S.FootNoteText>
              E para que as empresas não precisem se preocupar com<br />
              toda esta complexidade que nós desenvolvemos a CAT
            </S.FootNoteText>
          </S.FootNote>
        </S.Footer>
      </S.CatBigData>      
  );
};

export default CatBigData;