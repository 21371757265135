import React from "react";
import "../bulma.scss";

import CatLayout from "../components/CatLayout";
import CatAbout from "../components/CatAbout";
import CatAboutBanner from "../components/CatAboutBanner";
import CatBigData from "../components/CatBigData";
import CatSolution from "../components/CatSolution";
import CatSolutionBanner from "../components/CatSolutionBanner";
// import CatClients from "../components/CatClients";
import CatCases from  "../components/CatCases";
import CatJobsBanner from "../components/CatJobsBanner";
// import SEO from "../components/seo";
// import CustomerColumns from "../components/CustomerColumns";
// import PartnerColumns from "../components/PartnerColumns";
// import CasesSection from "../components/CasesSection";
// import ServicesSection from "../components/ServicesSection";
// import SlideShow from "../components/SlideShow";
// import SliderSeeMore from "../components/SliderSeeMore";
// import CtaBanner from "../components/CtaBanner";
// import HeroSection from "../components/HeroSection";

const Home = () => {
  return (
    <CatLayout>
      <CatAbout />
      <CatAboutBanner />
      <CatBigData />
      <CatSolution />
      <CatSolutionBanner />
      {/* <CatClients />
      <CatCases />
      <CatJobsBanner /> */}
      {/* <SEO title="ESX" />
      <SlideShow />
      <SliderSeeMore />
      <ServicesSection />
      <PartnerColumns title="Parceiros" />
      <CasesSection cases={cases} />
      <CustomerColumns title="Clientes" />
      <CasesSection forceRevert={true} cases={jobs} /> */}
    </CatLayout>
  );
};

export default Home;
