import React from "react";

import CTABanner from "../CtaBanner";

const CatAboutBanner = () => {
  const catThemeItemsDark = {
    main: "var(--cat-primary)",
    color: "var(--cat-primary)",
    boldTextColor: "#ad76f4",
    gradient: "var(--dark-gradient)",
    textColor: "#dcdcdc",
  };

  return (    
    <CTABanner
      title="Descubra pra que serve a CAT e como ela é aplicada no dia a dia do seu negócio"
      subtitle="Saiba como o Big Data pode alavancar o seu negócio através de Customer Analytics"
      theme={catThemeItemsDark}
      buttonText="Explorar"
      buttonLink="/"
      openAsExternalLink
    />
  );
};

export default CatAboutBanner;