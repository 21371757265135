import styled from "styled-components";
import media from "styled-media-query";

export const CatCases = styled.div`
  background-color: #E0E0E0;
  padding: 3rem var(--default-padding-desktop);
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.lessThan("medium")`
    padding: var(--default-padding-mobile);
  `}
`;

export const TitleWrapper = styled.div``;