import React from "react";

import CTABanner from "../CtaBanner";

const CatSolutionBanner = () => {
  const catThemeItemsDark = {
    main: "var(--cat-primary)",
    color: "var(--cat-primary)",
    boldTextColor: "#ad76f4",
    gradient: "var(--dark-gradient)",
    textColor: "#dcdcdc",
  };

  return (
    <CTABanner
      title="O mundo hoje trabalha com dados"
      subtitle="Entenda como funcionam as ferramentas de negócios da CAT"
      theme={catThemeItemsDark}
      buttonText="Soluções"
      buttonLink="/solucoes"
      openAsExternalLink
    />
  );
};

export default CatSolutionBanner;