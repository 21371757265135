import styled from "styled-components";
import media from "styled-media-query";

export const CatAbout = styled.div`
  background-image: var(--cat-about-background-image);
  background-size: 100%;
  background-position-y: 50%;
  
  padding: 6rem var(--default-padding-desktop);    
  display: flex;
  flex-direction: column;  
  align-items: baseline;
  ${media.lessThan("medium")`
    padding: var(--default-padding-mobile);
  `}

  ${media.greaterThan("3600px")`
  background-size: cover;
  background-repeat: no-repeat;
  height: 480px;
  background-origin: border-box;
`}
`;

export const Wrapper = styled.div`  
  padding-left: 10%;
   
 
  .title-icon {
    width: 15px;
    height: 15px;
    margin-top: 17px;
    float: left;
  }
  @media screen and (min-width: 100px) and (max-width: 768px){
    padding-top: 5%;
  }
  
`;

export const WrapperText = styled.div`
  padding-left: 30px;
`;

export const Title = styled.h1`  
  font-family: Exo2;
  font-weight: bold;
  color: #ffffff;
  font-size: var(--medium-title-size);
`;

export const Subtitle = styled.label`
  font-family: Exo2;
  color: #A0A0D7;
  strong {
    color: #8630F2;
  }
`;

export const Spacer = styled.div`
  height: 28px;
`;

export const Footer = styled.div`
  width: 78%;
  
  .logo {
    margin-top: 20px;
    margin-left: -50px;
    width: 100px;
    height: 100px;    
    background-color: transparent;   
    float: left;
  }
`;

export const FootNote = styled.div`
  padding: 30px 0;
  padding-left: 70px;
  background-color: #8630F2;
`;

export const FootNoteText = styled.span`
  font-family: Exo2;
  font-weight: bold;
  font-size: 15pt;
  color: #ffffff;
`;